
import { Component, Prop } from 'vue-property-decorator'
import { AnyObject, ResourceActionFailed } from '@movecloser/front-core'

import { ConnectorErrors, Inject, logger } from '../../../../support'
import { FormErrorsMixin } from '../../../../support/mixins'

import { AuthControlServiceType, IAuthControl } from '../../contracts'
import { authSSOSelectedProvider, AuthSSOSource, authSSOSource } from '../../config/auth'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'SignupBaseMixin'
})
export class SignupBaseMixin extends FormErrorsMixin {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Prop({ type: Object, required: true })
  public socialsConfig!: AnyObject

  public socialPending: boolean = false

  /**
   * Callback to sign with socials.
   */
  public async socialLogin (social: string): Promise<void> {
    try {
      this.cleanError()
      this.socialPending = true

      /** Stores selected social provider */
      localStorage.setItem(authSSOSelectedProvider, social)
      /** Stores selected source of authentication */
      localStorage.setItem(authSSOSource, AuthSSOSource.Auth)

      const redirectUri = await this.authControl.getSocialAuthRedirectUri(social)

      if (redirectUri) {
        window.location.href = redirectUri
      }
    } catch (error) {
      this.setError(error as Error)
    } finally {
      this.socialPending = false
    }
  }

  /**
   * Cleans the error
   */
  protected cleanError (): void {
    this.error = null
  }

  protected setError (error: Error): void {
    if (error instanceof ResourceActionFailed) {
      if (error.status === ConnectorErrors.ServerError ||
        error.status === ConnectorErrors.Unknown) {
        logger(error.message, 'error')
      }
    } else {
      logger(error.message, 'error')
    }

    this.error = error.message
  }
}

export default SignupBaseMixin
