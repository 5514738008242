

































































































import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import set from 'lodash/set'

import Consents from '../../../shared/molecules/Consents/Consents.vue'
import { Inject } from '../../../../support'
import { ISiteService, SiteServiceType } from '../../../../contexts'

import {
  CompanyValidateLoginRegisterFormFieldsetPayload
} from './CompanyValidateLoginRegisterFormFieldset.contracts'
import { FormErrorsMixin } from '../../../../support/mixins'
import {
  GuestDetailsFormFieldsetProps as Data
} from '../../../checkout/molecules/GuestDetailsFormFieldset'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CompanyValidateLoginRegisterFormFieldset>({
  name: 'CompanyValidateLoginRegisterFormFieldset',
  components: { Consents },
  created () {
    this.composeFormContexts()
  }
})
export class CompanyValidateLoginRegisterFormFieldset extends Mixins(FormErrorsMixin) {
  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  /**
   * @override
   */
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CompanyValidateLoginRegisterFormFieldsetPayload

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly consents!: AnyObject

  @Prop({ type: Object, required: false, default: null })
  public errors!: Data['_errors']

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withCompanyCheckboxFields!: boolean

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly pages!: Record<string, string>

  public onlyCompany: boolean | null = null
  public isCompany: boolean = false

  public get consentsOptions (): AnyObject[] {
    return this.consents.options
  }

  public onIsCompanyUpdate (value: boolean) {
    this.isCompany = value
    this.$emit('companyUpdate', value)
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    this._formData = newFormData

    this.$emit('update:errors')
  }

  protected composeFormContexts () {
    if (!this.siteService) {
      return
    }

    const settings = this.siteService.getProperty('settings') as AnyObject | null

    this.onlyCompany = !!settings?.onlyCompany

    if (this.onlyCompany) {
      this.onIsCompanyUpdate(true)
    }
  }
}

export default CompanyValidateLoginRegisterFormFieldset
