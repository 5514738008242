































import { Component, Mixins, Prop } from 'vue-property-decorator'
import {
  AnyObject,
  Authentication,
  AuthServiceType,
  EventbusType,
  IEventbus
} from '@movecloser/front-core'

import { FormErrorsMixin } from '../../../../../support/mixins'
import { Inject } from '../../../../../support'
import { ISiteService, SiteServiceType } from '../../../../../contexts'

import { CompanyRepositoryType, ICompanyRepository } from '../../../../profile/repositories/company'
import {
  CompanyValidateLoginRegisterFormFieldset,
  CompanyValidateLoginRegisterFormFieldsetPayload
} from '../../../../shared/molecules/CompanyValidateLoginRegisterFormFieldset'
import { Form, ValidationRules } from '../../../../shared/molecules/Form'

import { RouteNames as AuthRouteNames } from '../../../routes'
import { SignupFormPayload } from '../../../contracts'
import { UserModel } from '../../../shared'

import SignupBaseMixin from '../Signup.mixin'
import SocialsLogin from '../partials/SocialsLogin/SocialsLogin.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<SignupCompanyValidate>({
  name: 'SignupCompanyValidate',
  components: { CompanyValidateLoginRegisterFormFieldset, Form, SocialsLogin }
})
export class SignupCompanyValidate extends Mixins(FormErrorsMixin, SignupBaseMixin) {
  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Inject(CompanyRepositoryType)
  protected readonly companyRepository!: ICompanyRepository

  @Inject(EventbusType)
  protected readonly eventBus!: IEventbus

  @Inject(SiteServiceType)
  public readonly siteService!: ISiteService

  @Prop({ type: Object, required: false, default: () => ({}) })
  public readonly consents!: AnyObject

  @Prop({ type: Object, required: true, default: null })
  public readonly validatorsMapConfig!: ValidationRules | Record<string, ValidationRules> | null

  public hasCompanyEmailError: boolean = false
  public isCompany: boolean = false
  public isLoading: boolean = false

  public formData: CompanyValidateLoginRegisterFormFieldsetPayload = {
    acceptPrivacy: false,
    acceptContent: false,
    emailOffers: false,
    email: '',
    firstName: '',
    lastName: '',
    password: '',
    passwordConfirmation: '',
    vatId: ''
  }

  public get hasValidatorsMap (): boolean {
    return !!this.validatorsMap
  }

  public get pages (): Record<string, string> {
    return this.siteService.getActiveSiteUrls()
  }

  public get validatorsMap (): ValidationRules | undefined {
    if (
      !this.validatorsMapConfig ||
      !Object.prototype.hasOwnProperty.call(this.validatorsMapConfig, 'company') ||
      !Object.prototype.hasOwnProperty.call(this.validatorsMapConfig, 'guest')
    ) {
      return
    }

    return this.isCompany
      ? this.validatorsMapConfig.company as ValidationRules
      : this.validatorsMapConfig.guest as ValidationRules
  }

  public onIsCompanyUpdate (value: boolean): void {
    this.isCompany = value
  }

  public onFail (error: any): void {
    this.setError(error)
    this.isLoading = false
  }

  public async onSuccess (): Promise<void> {
    if (this.hasCompanyEmailError) {
      return
    }

    this.$store.commit('auth/SET_REDIRECT_TO', { name: `auth.${AuthRouteNames.SignUpWelcome}` })
  }

  public async signup (): Promise<void> {
    this.hasCompanyEmailError = false
    this.isLoading = true

    if (this.isCompany) {
      const isCompanyEmailAvailable = await this.companyRepository.isCompanyEmailAvailable(this.formData.email)

      if (!isCompanyEmailAvailable) {
        this.hasCompanyEmailError = true
        this.isLoading = false
        return
      } else {
        const tokenModel = await this.companyRepository.shortCreateCompany({
          email: this.formData.email,
          password: this.formData.password,
          passwordConfirmation: this.formData.passwordConfirmation,
          vatTaxId: this.formData.vatId!
        })

        this.trackSignup()

        this.$store.commit('auth/SET_REDIRECT_TO', {
          name: `auth.${AuthRouteNames.CompanyCreate}`,
          query: { vatId: this.formData.vatId }
        })

        this.authService.setToken(tokenModel.toAuthToken())
      }
    } else {
      const tokenModel = await this.authControl.signup({
        ...this.formData,
        subscribeToNews: false
      } as SignupFormPayload)

      this.trackSignup()

      this.authService.setToken(tokenModel.toAuthToken())
    }

    this.isLoading = false
  }

  private trackSignup (): void {
    this.eventBus.emit(
      'app:authorization.sign_up',
      {
        email: this.formData.email,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        emailOffers: this.formData.emailOffers,
        smsOffers: false
      }
    )

    if (this.formData.emailOffers) {
      this.eventBus.emit('app:newsletter.consents', {
        email: this.formData.email,
        firstName: this.formData.firstName,
        lastName: this.formData.lastName,
        emailOffers: this.formData.emailOffers
      })
    }
  }
}

export default SignupCompanyValidate
