




























import { Component, Prop, Vue } from 'vue-property-decorator'

import { SocialAuthType } from '../../../../contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({
  name: 'SocialsLogin'
})
export class SocialsLogin extends Vue {
  @Prop({ type: Boolean, required: true, default: false })
  public readonly socialPending!: boolean

  public socialAuthType = SocialAuthType

  public socialLogin (type: string): void {
    this.$emit('socialLogin', type)
  }
}

export default SocialsLogin
